import React from "react";

const cards = [
  {
    id: 1,
    heading: "The Heartbeat of Aspirants Ally: Our People",
    title: "At Aspirants Ally, our people are our greatest asset. We value diversity, foster a collaborative environment, and prioritize employee growth. We believe in creating a workplace where everyone feels valued, respected, and empowered to contribute their best. Our team is a vibrant mix of talented individuals with diverse backgrounds and experiences, bringing unique perspectives to every challenge.",
  },
  {
    id: 2,
    heading: "A Day in the Life at Aspirants Ally",
    title: "A typical day at Aspirants Ally is a dynamic blend of teamwork and individual contributions. We start our day with a team huddle to share updates and align on priorities. Collaboration is key, with frequent brainstorming sessions and cross-functional teamwork. We prioritize work-life balance, offering flexible work arrangements and encouraging employee wellbeing.",
  },
  {
    id: 3,
    heading: "Our Work: Making a Difference",
    title: "At Aspirants Ally, we are passionate about making a positive impact. We strive to deliver exceptional results for our students, exceeding their expectations and helping them achieve their goals. We are committed to continuous learning and innovation, embracing new technologies and approaches to stay ahead of the curve. We believe in giving back tothe community and actively supporting initiatives that make a difference. We provide top-notch services to enhance your skills. Join us and explore our professional courses tailored to your needs.",
  },
];

const Card = ({ heading, title, }) => (
  <div className="rounded-lg shadow-md overflow-hidden border border-white">
    <div className="p-4 bg-[#233871]">
      <h3 className="text-lg font-semibold italic text-white">{heading}</h3>
    </div>
    <div className="p-5 font-medium text-justify"> 
      <p>{title}</p>
    </div>
  </div>
);

const AboutOurWork = () => (
  <section className="bg-slate-50 py-12">
    <div className="container mx-auto px-8 sm:px-12 lg:px-16">
      {" "}
      <h2 className="text-2xl font-semibold text-[#233871] mb-2 font-poppins"></h2>{" "}
      <h3 className="text-3xl font-semibold text-black mb-8 font-poppins"></h3>{" "}
      <h2 className="text-3xl font-bold text-[#3e5ba9] mb-4">
        Know More About our
        <span className="text-[#f6c16f]"> people & our work</span>
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {" "}
        {cards.map((card) => (
          <Card key={card.id} {...card} />
        ))}
      </div>
    </div>
  </section>
);

export default AboutOurWork;
