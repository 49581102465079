import React, { useState } from 'react';
import { Link } from 'lucide-react';
import profile from './medicalassets/Ethan-Caldwell.webp.png';
import Image from './medicalassets/Container.png';
import { MapPin } from 'lucide-react';

const MedicalStories = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const slides = [
    {
      title: "Dive deep into challenging topics in Physics, Chemistry, and Biology.",
      author: "Ethan Caldwell",
      date: "July 7, 2024",
      tag: "Medical",
      image: Image,
    },
    {
      title: "Explore the wonders of the Universe with hands-on experiments.",
      author: "Sophia Lewis",
      date: "August 15, 2024",
      tag: "Science",
      image: Image,
    },
    {
      title: "Innovations in technology and their impact on healthcare.",
      author: "Ava Garcia",
      date: "September 5, 2024",
      tag: "Technology",
      image: Image,
    },
  ];

  const handleDotClick = (index) => {
    setCurrentSlide(index);
  };

  return (
    <div className="max-w-8xl mx-auto p-4 md:p-8 flex flex-col lg:flex-row gap-8 bg-gray-100">
      {/* Left Content Section */}
      <div className="w-full lg:flex-[4] space-y-6">
        <div className="prose max-w-none text-[#29294B]">
          <h3 className="text-2xl font-semibold mb-6">Stories that Matter</h3>
          <p className="mb-6">
            <b>Welcome to The Aspirant's Ally</b>, your trusted partner in overseas education. We guide
            you through the entire journey, from choosing the right university and program to securing
            your visa and beyond.
          </p>
          <p className="mb-6">
            <b>Your Aspirants Ally Adventure Starts Now</b>, your gateway to global opportunities. We are a leading
            overseas education consultancy dedicated to helping ambitious students like you achieve
            their academic dreams abroad. Our expert team provides personalized guidance on
            university selection, application assistance, visa processing, and pre-departure support.
            Let us be your ally in this exciting journey.
          </p>
          {/* <p className="mb-6">
            At the core of Revision is a commitment to delivering stories that matter.
            Unlike traditional media platforms or news, Revision invites readers into a
            world of deeply personal narratives. The website’s title, "Heartfelt
            Reflections: Stories of Love, Loss, and Growth," signals this intent clearly,
            inviting you to journey through the most intimate aspects of human
            experience.
          </p>
          <ul className="list-disc pl-5 space-y-2">
            <li>
              <span className='font-semibold'>Author Profiles:</span> Each contributor has a detailed profile, allowing readers
              to connect with their personal journey and social media presence.
            </li>
            <li>
              <span className='font-semibold'>Experience Widgets:</span> Contributors showcase their professional growth
              and skills, giving readers insight into their expertise.
            </li>
            <li>
              <span className='font-semibold'>Technologies Section:</span> Creators highlight the tools they use, such as
              Figma, Photoshop, and more, providing transparency in their creative
              processes.
            </li>
            <li>
              <span className='font-semibold'>Creating Widget:</span> A space where contributors can link to external
              projects and portfolios, expanding their reach beyond the platform.
            </li>
          </ul> */}
        </div>
      </div>

      {/* Right Sidebar Section */}
      <div className="w-full lg:flex-[2] space-y-6">
        <div className="bg-white p-4 md:p-6 rounded-lg shadow-sm">
          <div className="flex items-center gap-4 mb-4">
            <img
              src={profile}
              alt="Author"
              className="w-10 h-10 rounded-full"
            />
            <div>
              <h3 className="font-semibold">Page Varner</h3>
              <p className="text-sm text-gray-500">Experience Designer</p>
            </div>
          </div>
          {/* <p className="text-sm text-gray-600 mb-4">
            Thanks for checking out my blog site. I am a student at Penn
            State University studying computer science.
          </p> */}
          <div className="flex items-center text-gray-600 mb-4">
            <MapPin className="w-5 h-5 mr-1 text-gray-500" />
            <p>Bhopal, Madhya Pradesh</p>
          </div>
          <div className="flex gap-2">
            <Link className="w-5 h-5 text-gray-400" />
            <Link className="w-5 h-5 text-gray-400" />
            <Link className="w-5 h-5 text-gray-400" />
          </div>
        </div>

        {/* Experience Timeline */}
        <div className="bg-white p-4 md:p-6 rounded-lg shadow-sm space-y-6">
          <h6 className="font-semibold text-gray-900">UPCOMING EVENTS</h6>
          {/* <div className="space-y-4">
            {[{ title: "NIST Preparation Webinar", date: "2023", description: "Join us as Sarah Harris will lead and demonstrate this framework." }, { title: "Meet Text Series", date: "2022", description: "Working with the team to get more users to see and enjoy using software." }, { title: "Open House Details", date: "2021", description: "Visit our campus, meet faculty and learn about your tech training journey." }].map((event, index) => (
              <div key={index} className="border-b border-gray-200 pl-4 py-2">
                <div className="flex justify-between items-center">
                  <h5 className="font-medium text-gray-900">{event.title}</h5>
                  <span className="text-sm text-gray-500">{event.date}</span>
                </div>
                <p className="text-sm text-gray-600 mt-1">{event.description}</p>
              </div>
            ))}
          </div> */}
        </div>
      </div>

      {/* Slider Section */}
      <div className="w-full lg:flex-[3] space-y-4">
        <h4 className="font-semibold text-gray-900">BLOG</h4>
        <div className="slider-container relative overflow-hidden rounded-lg shadow-lg">
          <div
            className="slider-track flex transition-transform duration-500"
            style={{ transform: `translateX(-${currentSlide * 100}%)` }}
          >
            {slides.map((slide, index) => (
              <div key={index} className="slide flex-shrink-0 w-full relative">
                <img
                  src={slide.image}
                  alt={slide.title}
                  className="w-full h-48 md:h-64 object-cover rounded-lg"
                />
                <div className="absolute inset-0 bg-black bg-opacity-40 flex flex-col justify-end p-4 rounded-lg text-white">
                  <span className="absolute top-4 left-4 bg-teal-600 text-white px-2 py-1 rounded-full text-xs">
                    {slide.tag}
                  </span>
                </div>
              </div>
            ))}
          </div>

          {/* Pagination Dots */}
          <div className="absolute bottom-2 left-1/2 transform -translate-x-1/2 flex gap-2">
            {slides.map((_, index) => (
              <button
                key={index}
                className={`w-3 h-3 rounded-full ${index === currentSlide ? 'bg-gray-800' : 'bg-gray-400'}`}
                onClick={() => handleDotClick(index)}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MedicalStories;
