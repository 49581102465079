// import { cn } from "../../lib/utils";
// import Marquee from "../../components/magicui/marquee";
import React from "react";
import Marquee from "../../magicui/marquee";
import { cn } from "../../../lib/utils";

const imagesSmall = [
  "https://media.istockphoto.com/id/1842247191/photo/young-indian-student-woman-wearing-backpack-and-holding-books-over-isolated-green-screen.jpg?s=612x612&w=0&k=20&c=bydzcIMA3m23VyRJ6mXUqHLO7kaZAKxwxklJC-E0WO0=",
  "https://media.istockphoto.com/id/1864864052/photo/cheerful-indian-girl-showing-smart-phone-with-blank-screen-and-victory-gesture.jpg?s=612x612&w=0&k=20&c=xTkZPTJf_2UVxbQKD-HJmvtZwXhMlrGRvk8NMjxQ8Ps=",
  "https://media.istockphoto.com/id/1828874105/photo/young-cheerful-indian-man-outdoor-background.jpg?s=612x612&w=0&k=20&c=60aoWq1qag-ob1PSCwkEG0W5fM9i2_4Oj5i-T49GotA=",
  "https://media.istockphoto.com/id/1486726433/photo/portrait-of-a-smiling-girl-of-indian-ethnicity-holding-a-tablet-phone-in-hand-and-showing.jpg?s=612x612&w=0&k=20&c=tgq4G6MX_jKcmFZuCkyWTYIKH1nIZkO_fO9uVMXdU4k=",
  "https://media.istockphoto.com/id/2052831246/photo/portrait-of-attractive-cheerful-girl-touching-specs-looking-at-camera-copy-space-isolated.jpg?s=612x612&w=0&k=20&c=GvwyGKSP8kZLO-AblIoSJ03HjQQ7bUM5r1qhaRimTDo=",
  "https://media.istockphoto.com/id/1134003578/photo/smiling-woman-wearing-eyeglasses-outdoor.jpg?s=612x612&w=0&k=20&c=obFbEWz4lii-wrWBVVExIRAW0T_-JjQdQICgUkU567M=",
];

const reviews = [
  {
    name: "Khushi Singh",
    username: "@student1",
    body: "Aspirants Ally's IELTS prep course was a game-changer! Their expert instructors and comprehensive materials helped me achieve my target score. I highly recommend them to anyone aspiring to study or work abroad.",
    img: imagesSmall[0],
  },
  {
    name: "Ayushi Sharma",
    username: "@student2",
    body: "The USMLE prep resources provided by Aspirants Ally were invaluable. Their dynamic and practice exam closely simulated the actual exam, and the detailed explanations helped me understand the concepts thoroughly. Thanks to Aspirants Ally, I passed the USMLE with flying colors!",
    img: imagesSmall[1],
  },
  {
    name: "Mansi Verma",
    username: "@student3",
    body: "Aspirants Ally's guidance on the study abroad process was exceptional. They helped me choose the right university, navigate the application process, and excel with my applications. I'm now living my dream in [Country Name]!",
    img: imagesSmall[2],
  },
  {
    name: "Niharikha Shrivas",
    username: "@student4",
    body: "The NEET predictor tool from Aspirants Ally was incredibly accurate. It helped me identify my strengths and weaknesses, as well as the colleges that matched my profile. This was a major factor in my successful NEET score.",
    img: imagesSmall[3],
  },
  {
    name: "Ashutosh",
    username: "@student5",
    body: "Aspirants Ally's psychometric assessment helped me gain insights into my potential and career interests. The results, combined with their expert report and guidance, changed me and made informed decisions about my future.",
    img: imagesSmall[4],
  },
  {
    name: "Ahmed Ali",
    username: "@student6",
    body: "Aspirants Ally's MBBS prep course was incredibly helpful. Their comprehensive study materials, mock tests, and expert guidance gave me the confidence I needed to ace the entrance exams. I am now pursuing my dream of becoming a doctor.",
    img: imagesSmall[5],
  },
  {
    name: "Ritika Soni",
    username: "@student7",
    body: "TOEFL was really struggling with my English proficiency, but Aspirants Ally's TOEFL prep course was a lifesaver. Their expert instructors and comprehensive materials helped me improve significantly, and I achieved a score that exceeded my expectations. I'm now confident in my ability to succeed in an English-speaking environment.",
    img: imagesSmall[6],
  },
  {
    name: "Manali Shrivastava",
    username: "@student8",
    body: "The GRE can be daunting, but Aspirants Ally's prep course made it manageable. Their practice tests were incredibly helpful in identifying my weaknesses, and the detailed explanations helped me understand the concepts thoroughly. Thanks to Aspirants Ally's guidance, I scored much higher than I anticipated.",
    img: imagesSmall[7],
  },
  {
    name: "Abhay",
    username: "@student9",
    body: "Aspirants Ally's GMAT prep course was the best investment I made for my MBA application. Their experienced instructors provided valuable insights into the exam format and test-taking strategies. I'm confident that Aspirants Ally's guidance helped me achieve a competitive GMAT score!",
    img: imagesSmall[8],
  },
  {
    name: "Sundram",
    username: "@student10",
    body: "I was initially overwhelmed by the SAT, but Aspirants Ally's prep course gave me the structure and support I needed to succeed. Their personalized approach made all the difference, and I scored much higher than I ever imagined. I'm thrilled with my final score!",
    img: imagesSmall[9],
  },
  {
    name: "Hiba khan", 
    username: "@student11",
    body: "The MCQCCE is challenging and daunting, but Aspirants Ally's comprehensive prep course gave me the confidence I needed to pass. Their practice questions were incredibly thorough, and the tips from their expert instructors were invaluable in preparing for the exam.",
    img: imagesSmall[10],
  },
  {
    name: "Aditya Sharma",
    username: "@student12",
    body: "I was initially apprehensive about the PLAB, but Aspirants Ally's prep course was top-notch. Their expert instructors and comprehensive resources made all the difference. Their practical advice helped me understand the concepts effectively, and I'm grateful for Aspirants Ally's support in achieving my goal of practicing medicine in the UK.",
    img: imagesSmall[11],
  },
];


const firstRow = reviews.slice(0, reviews.length / 2);

const ReviewCard = ({ img, name, username, body }) => {
  return (
    <figure
      className={cn(
        "relative w-72 cursor-pointer overflow-hidden rounded-xl p-8 ",
        " bg-white hover:border shadow-2xl shadow-white border"
      )}
    >
      <div className="flex flex-row items-start gap-4 ">
        <div className="h-10 w-10 o object-cover object-center">
          <img
            className="rounded-full h-full w-full object-cover object-center"
            alt=""
            src={img}
          />
        </div>
        <div className="flex flex-col">
          <figcaption className="text-sm font-medium dark:text-white">
            {name}
          </figcaption>
          <p className="text-xs font-medium dark:text-white/40">{username}</p>
        </div>
      </div>
      <blockquote className="text-sm pt-5">{body}</blockquote>
    </figure>
  );
};

export function ReviewMarquee() {
  return (
    <div className="w-full flex-col overflow-hidden rounded-lg bg-gray-50 z-40 ">
      <Marquee pauseOnHover className="[--duration:20s] ">
        {firstRow.map((review) => (
          <ReviewCard key={review.username} {...review} />
        ))}
      </Marquee>
    </div>
  );
}
