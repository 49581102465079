import React from "react";

const offerings = [
  {
    title: "Our Curricula",
    description:
      "Uncover a wide selection of courses tailored to your interests and professional ambitions. We deliver expertly designed programs with a structured approach.",
  },
  {
    title: "Our Programs",
    description:
      "Find courses that match your interests and career aspirations. We offer expertly developed, well-structured programs to help you succeed.",
  },
  {
    title: "Exceptional Video Content",
    description:
      "High-definition video lectures, premium video instruction, and top-tier video lessons.",
  },
  {
    title: "World-Class Faculty",
    description:
      "Renowned instructors, industry-leading experts, and highly skilled educators.",
  },
  {
    title: "Personalized Learning Path",
    description:
      "Adaptable learning schedule, flexible learning options, and convenient learning pathways.",
  },
  {
    title: "In-Depth Learning Resources",
    description:
      "Comprehensive study guides, abundant learning materials, and extensive learning resources.",
  },
];

const InfoSectionWithSwiper = () => {
  return (
    <div className="mx-auto py-28 md:py-0 px-4 flex flex-col mb-20 md:flex-row items-center bg-slate-50">
      <div className="container py-12 px-4 border-8 bg-[#243771] border-white shadow-md shadow-blue-300 flex flex-col md:flex-row items-center rounded-3xl">
        <div className="w-full mb-8 md:mb-0 md:pr-8 rounded-lg text-[#FFF] mx-6">
          <h2 className="text-3xl font-bold mb-4">Our Offerings</h2>
          <p className="text-lg mb-6">
            Explore a diverse range of courses aligned with your goals and
            passions. We provide meticulously crafted programs developed by
            industry leaders.
          </p>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">
            {offerings.map((offering, index) => (
              <div
                key={index}
                className="w-full h-auto rounded-xl text-black p-4 bg-white shadow-md hover:shadow-lg transition-shadow"
              >
                <p className="font-bold text-lg uppercase mb-2">
                  {offering.title}
                </p>
                <p className="text-sm">{offering.description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default InfoSectionWithSwiper;
